import React from 'react';
import { IoLogoAndroid, IoMdCloseCircleOutline } from "react-icons/io";
import { GoStarFill } from "react-icons/go";
import { BsFillCloudArrowDownFill } from "react-icons/bs";


import './ModalBack.css'; // Asegúrate de tener un archivo Modal.css en la misma carpeta
import { FaApple } from 'react-icons/fa';

const Modal = ({ isOpen, closeModal, app, setOpenModal }) => {

  return (
    <div className='totally'>
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className='button-contener'>
            <IoMdCloseCircleOutline onClick={closeModal} className='Button-exit' fill='#485475' />
            </div>
            <div className='component'>
              <img src={app.imageUrl} alt={app.name} className="app-image" style={{ width: '83px', borderRadius: 12 }} />
              <div className='box-data'>
                <IoLogoAndroid className="app-icon" fill="#a6b0cf"/><FaApple className="app-icon" fill="#a6b0cf"/>
            <div className="contener">
              <GoStarFill className="app-icons" fill="#ffc107"/>
              <p>{app.rating}</p>       
            </div>
              <div className="contener">
              <BsFillCloudArrowDownFill className="app-icon" fill="#a6b0cf"/>
              <p>{app.downloads}</p>       
            </div>
              </div>
            </div>
            <p className='author-modal'>JustApp</p>    
            <div className='information-modal'>
              <p className='text-title-c'>{app.name}</p>
              <p className='text-c'>{app.description}</p>
            </div>
            <div className='Footer'>
              <p className='text-title'>Download ready</p>
              <p className='text-Footer'>Click the button below in order to start with your app download.</p>
            </div>
            <button className='Button-star'  onClick={() => { setOpenModal(true) }}>DOWNLOAD NOW</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;