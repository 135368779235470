import "./LoadsS.css";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { v4 as uuidv4 } from "uuid"; // Import uuid library
import Modal from '../Modal/index'

import image from './assets/u8YKYC6HPZimage_2023-08-25_213211071.png'
import image2 from './assets/kadhSAUUQBimage_2023-08-10_092204127.png'
import image3 from './assets/K1mYx4gqEIimage_2023-09-01_130344717.png'
import image4 from './assets/BKnnB8S8F5image_2023-12-07_181311674.png'
import image5 from './assets/freefire.png'
import image6 from './assets/ef.jpeg'
import image7 from './assets/wz.png'


const apps = [
  { name: "COD Mobile+", rating: "4.9", imageUrl: image, downloads:'423k+', description: "You can get endless gems for COD Mobile by using our modified app, which working perfectly on both iOS and Android devices."},
  { name: "eFootball+", rating: "4.9", imageUrl: image6, downloads:'423k+', description: "You can get endless gems for eFootball by using our modified app, which working perfectly on both iOS and Android devices."},
  { name: "FreeFire+", rating: "4.9", imageUrl: image5, downloads:'500k+',  description: "You can get endless diamonds for FreeFire by using our modified app, which working perfectly on both iOS and Android devices" },
  { name: "FC Mobile+", rating: "4.9", imageUrl: image3, downloads:'1M+',  description: "You can get endless gems and coins for FC Mobile by using our modified app, which working perfectly on both iOS and Android devices." },
  { name: "DLS 24++", rating: "4.9", imageUrl: image4, downloads: '643K+', description: "Newest DLS 24 patch has been fixed - We implemented newest update in our DLS 24++ APP! Get it now for iOS & Android device and test it out!" },
  { name: "TikTok Coins++", rating: "4.9", imageUrl: image2, downloads: '2M+', description: "Current only-working and patched TikTok++ app on the market. Tested and fixed for both platforms - iOS & Android." },
  { name: "Warzone Mobile+", rating: "5", imageUrl: image7, downloads:'423k+', description: "You can get endless gems for Warzone Mobile by using our modified app, which working perfectly on both iOS and Android devices."},

];

const settings = {
  dots: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: true, // Add this line to disable the infinite scrolling
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
  autoplay: true,
};


export default function ItemsLoading({setOpenModal}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAppIndex, setSelectedAppIndex] = useState(null);

  const openModal = (index) => {
    setSelectedAppIndex(index);
    setIsOpen(true);
  };


  const closeModal = () => {
    setIsOpen(false);
  };
  
  return (
    <div className="trending-apps">
   
      <div className="trending-apps-title-container">
        <div className="ssection-title-wrapper">
          <h3>Trending Apps</h3>
        </div>
       <Slider {...settings} arrows={false}>
          {apps.map((app, index) => (   
      <div className="contenedor" key={index} onClick={() => openModal(index)}>
      <div className="card" key={uuidv4()}>
      <div className="card-content">
        <img src={app.imageUrl} alt={app.name} style={{ width: '83px', borderRadius: 12 }} />
        <div className="apps-name">{app.name}</div>
        <div className="content">
          <h3 className="rating">☆</h3>
          <div className="ratingCount">{app.rating}</div>       
        </div>
      </div>
      </div>
    </div>
  ))}
        </Slider>
      </div>
      <Modal isOpen={isOpen} closeModal={closeModal} app={apps[selectedAppIndex]} setOpenModal={setOpenModal}/> 
    </div>
  );
};