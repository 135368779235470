import React, {useState} from 'react';
import { HeaderTop } from "../components/Cabecera";
import ItemsLoading from "../components/itemsLoads";
import ItemsLoading_all from "../components/itemsLoads2";
import Modal_Loading from '../components/Modal_loading';


function AppUI() {
  const [openModal, setOpenModal] = useState(false);
  
  return (
    <>
      <HeaderTop />
      <ItemsLoading setOpenModal={setOpenModal} />
      <ItemsLoading_all setOpenModal={setOpenModal} />
      <Modal_Loading openModal={openModal} />
    </>
  );
}

export { AppUI };
